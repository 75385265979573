import wheels from '../images/categories_img/Diamond_Wheels_Group.jpg'
import tripple_ripple from '../images/categories_img/Triple-Ripple.jpg'
import saw_blades from '../images/categories_img/saw-blades.jpg'
import belts from '../images/categories_img/belts.jpg'
import lapping_discs from '../images/categories_img/Lapping_Discs.jpg'
import polish from '../images/categories_img/polish.jpg'
import silicon_carbide_grits from '../images/categories_img/silicon-carbide-grits.jpg'
import diamond_pac_vibra from '../images/categories_img/Vibra-600.jpg'
import cryst_diamond_belts from '../images/categories_img/Crystalite_Plated_Belt.jpg'
import display from '../images/categories_img/DISPLAY_Banner.jpg'
import jewelery_tools from '../images/categories_img/Jewelry_Tools_Wholesale.jpg'
import mineral_oil from '../images/categories_img/mineral-oil.jpg'
import tumbler from '../images/categories_img/tumbler.jpg'
import saw_grid from '../images/categories_img/saw-grinder.jpg'
import chuck from '../images/categories_img/chuck-jaws.jpg'

export const productCategories = [
  {
    categoryId: 1001,
    categoryName: "ReSurfacing Soft Diamond Wheels",
    img: "",
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1002,
    categoryName: "Wheels",
    img: wheels,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1003,
    categoryName: "Tripple-Ripple Drills",
    img: tripple_ripple,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1004,
    categoryName: "Saw Blades",
    img: saw_blades,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1005,
    categoryName: "Belts",
    img: belts,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1006,
    categoryName: "Lapping Discs",
    img: lapping_discs,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1007,
    categoryName: "Polish",
    img: polish,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1008,
    categoryName: "Silicon Carbide Grits",
    img: silicon_carbide_grits,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1009,
    categoryName: "Diamond Pacific Vibra Dry",
    img: diamond_pac_vibra,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1010,
    categoryName: "Display",
    img: display,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1011,
    categoryName: "Jewelery Tools",
    img: jewelery_tools,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1012,
    categoryName: "Mineral Oil",
    img: mineral_oil,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1013,
    categoryName: "Tumbler",
    img: tumbler,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1014,
    categoryName: "Saw & Grinder",
    img: saw_grid,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1015,
    categoryName: "Chuck Jaws",
    img: chuck,
    dataFilePath: "",
    columns: [],
  },
  {
    categoryId: 1016,
    categoryName: "Crystlite Diamond Belts",
    img: cryst_diamond_belts,
    dataFilePath: "",
    columns: [],
  },
];
