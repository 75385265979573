import wheels from "../../images/categories_img/Diamond_Wheels_Group.jpg";

export const wheelsData = [
  {
    subCategoryId: 101,
    parentCategoryId: 1002,
    subCategoryName: "2 inch",
    img: wheels,
  },
  {
    subCategoryId: 102,
    parentCategoryId: 1002,
    subCategoryName: "4 inch",
    img: wheels,
  },
  {
    subCategoryId: 103,
    parentCategoryId: 1002,
    subCategoryName: "6 inch",
    img: wheels,
  },
  {
    subCategoryId: 104,
    parentCategoryId: 1002,
    subCategoryName: "8 inch",
    img: wheels,
  },
  {
    subCategoryId: 105,
    parentCategoryId: 1002,
    subCategoryName: "Covex Diamond Wheel",
    img: wheels,
  },
];
