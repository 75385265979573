// Auth Service is only for making HTTP request and sending the data back or storing some data in the localStorage

import axios from "axios";

const API_URL = "/api/customers/";

// Register User
const register = async (userData) => {
  const response = await axios.post(API_URL + "register-user/", userData);

  // Saving the token to the local storage if any repsonse is present
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Login User
const login = async (userData) => {
  const response = await axios.post(API_URL + "login-user/", userData);

  // Saving the token to the local storage if any repsonse is present
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

// Logout Function
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
