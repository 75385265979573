import React from "react";
import { AiOutlineCopyright } from "react-icons/ai";

const FooterBottom = () => {
  return (
    <div className="h-30 w-full bg-[#F5F5F3] group mt-auto">
      <div className="max-w-container mx-auto border-t-[1px] pt-10 pb-20">
        {/* <h2>Johnson Brothers Lapidary</h2> */}
        <h2 className="text-xl text-center mt-5 font-bold text-gray-800">
          Johnson Brothers Lapidary
        </h2>

        <div className="my-2 text-center">
          {" "}
          <a
            href="https://maps.app.goo.gl/Ft5DxahP4Gepy3nVA"
            target="_blank"
            class="font-medium text-center text-blue-600 dark:text-blue-500 hover:underline"
          >
            1452 N. Batavia St., Orange, CA 92867
          </a>
        </div>

        <div class="my-2 text-center">
          <p className="text-lg text-gray-900 dark:text-black">
            Phone: 714-771-7007
          </p>
        </div>

        <p className="text-titleFont font-normal text-center flex md:items-center justify-center text-lightText duration-200 text-sm">
          <span className="text-md mr-[1px] mt-[2px] md:mt-0 text-center hidden md:inline-flex">
            <AiOutlineCopyright />
          </span>
          Copyright {new Date().getFullYear()} | All Rights Reserved |
        </p>
      </div>
    </div>
  );
};

export default FooterBottom;
