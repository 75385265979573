import React, { useEffect, useState } from "react";
import wheels from "../../assets/images/categories_img/Diamond_Wheels_Group.jpg";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // Simulating fetching order history data from API
    const fetchOrderHistory = async () => {
      // Dummy data
      const dummyData = [
        {
          id: 1,
          orderNumber: 1234,
          date: "2022-01-01",
          totalAmount: 100.0,
          status: "Completed",
          items: [
            {
              id: "DP-100-M2-30H",
              quantity: 5,
              img: wheels,
              name: 'Diamond Pacific Nikel Bond 2" ',
              price: 50.0,
            },
            {
              id: "DP-100-M2-60H",
              quantity: 5,
              img: wheels,
              name: 'Diamond Pacific Nikel Bond Flat 6" ',
              price: 25.0,
            },
            {
              id: "DP-100-M2-60",
              quantity: 5,
              img: wheels,
              name: 'Diamond Pacific Resin Bond Flat 4" ',
              price: 25.0,
            },
          ],
        },
        {
          id: 2,
          orderNumber: 1235,
          date: "2022-01-02",
          totalAmount: 50.0,
          status: "Shipped",
          items: [
            {
              id: "DP-100-M2-30H",
              quantity: 5,
              img: wheels,
              name: 'Diamond Pacific Resin Bond Flat 2" ',
              price: 50.0,
            },
            {
              id: "DP-100-M2-60H",
              quantity: 2,
              img: wheels,
              name: 'Diamond Pacific Resin Bond Flat 8" ',
              price: 25.0,
            },
          ],
        },
      ];

      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setOrders(dummyData);
    };

    fetchOrderHistory();
  }, []);
  return (
    <div className="container mx-auto p-4">
      <Breadcrumbs title="Order History" />

      {orders &&
        orders.map((order) => (
          <div className="bg-white my-5 rounded shadow-md p-6" key={order.id}>
            <div className="flex items-center justify-between bg-grey-200 p-4 rounded-t">
              <div className="flex items-center">
                <h2 className="text-xl font-bold text-gray-800">
                  Order ID: {order.orderNumber}
                </h2>
                <span
                  className={`inline-block ml-5 px-2 py-1 text-sm font-semibold rounded ${
                    order.status === "Completed"
                      ? "bg-green-500 text-white"
                      : "bg-yellow-500 text-gray-800"
                  }`}
                  //   className="inline-block px-2 py-1 text-sm font-semibold rounded bg-green-500 text-white"
                >
                  {order.status}
                </span>
              </div>
              <button className="px-4 py-2 bg-blue-500 text-white rounded">
                Repeat Order
              </button>
            </div>
            {/* Card Content with Ordered Items Table */}
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">Ordered Items</h3>
              <table className="w-full border">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border p-2">SKU</th>
                    <th className="border p-2">Image</th>
                    <th className="border p-2">Name</th>
                    <th className="border p-2">Quantity</th>
                    <th className="border p-2">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item) => (
                    <tr key={item.id}>
                      <td className="border text-center p-2">{item.id}</td>
                      {/* <td className="border text-center p-2">{item.img}</td> */}
                      <img src={item.img} alt={item.name} className="ml-5" />
                      <td className="border text-center p-2">{item.name}</td>
                      <td className="border text-center p-2">
                        {item.quantity}
                      </td>
                      <td className="border text-center p-2">
                        ${(item.quantity * item.price).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Order Details */}
              <div className="flex items-center justify-between mt-4">
                <p className="text-md text-gray-600">Date: {order.date}</p>
                <p className="text-lg font-semibold text-blue-700">
                  Total Amount: ${order.totalAmount.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OrderHistory;
