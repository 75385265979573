import React from "react";
import NavTitle from "./NavTitle";
import { productCategories } from "../../../../assets/dummy/categories";
import { Link } from "react-router-dom";

const Price = () => {
  return (
    <div className="cursor-pointer">
      <NavTitle title="Shop by Category" icons={false} />
      <div className="font-titleFont">
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {productCategories.map((item) => (
            <Link to="/sub-category">
              <li
                key={item.categoryId}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
              >
                {item.categoryName}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Price;
