import React, { useEffect, useState } from "react";
import { CiShoppingCart } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/orebiSlice";

const ProductTablesList = ({ wheelsData, columns, data, categories }) => {
  // const [count, setCount] = useState(Array(data.length).fill(0));

  const [count, setCount] = useState({});
  useEffect(() => {
    // Update counts when apiData changes
    setCount(Array(data.length).fill(0));
  }, [data.length]);

  const incrementCount = (rowIndex) => {
    setCount((prevCounts) => ({
      ...prevCounts,
      [rowIndex]: (prevCounts[rowIndex] || 0) + 1,
    }));
  };

  const decrementCount = (rowIndex) => {
    setCount((prevCounts) => ({
      ...prevCounts,
      [rowIndex]: Math.max((prevCounts[rowIndex] || 0) - 1, 0),
    }));
  };

  const styles = {
    transition: "all 0.2s ease-out",
    h60: {
      height: "24rem",
    },
    w128: {
      width: "64rem",
    },
    container: {
      position: "relative",
    },
    img: {
      height: "100%",
      width: "100%",
      display: "block",
    },
    listItem: {
      fontSize: "14px",
      marginLeft: "10px",
      listStyleType: "circle",
      display: "inline",
    },
  };

  const listItems = wheelsData.map((data) => (
    <tr
      key={data.subCategoryId}
      className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500"
    >
      <td className="whitespace-nowrap px-6 py-2">{data.subCategoryName}</td>
    </tr>
  ));

  const dispatch = useDispatch();

  const handleAddToCart = (row, rowIndex) => {
    console.log("Added to Cart - SKU:", row["Our Price"]);
    dispatch(
      addToCart({
        _id: row.SKU,
        name: row.Brand,
        quantity: count[rowIndex],
        image: categories[1].img,
        // badge: productInfo.badge,
        price: row["Our Price"],
        // colors: productInfo.color,
      })
    );
  };

  return (
    <div className="flex flex-col">
      <div>
        {/* <img src={categories[1].img} alt="Wheels Image" height={50} width={50}/> */}
        {/* <CategoryInfo info={categories[1]} wheelsData={wheelsData[0]} /> */}
        <div>
          <div className="flex justify-right mb-5 items-center bg-blue-lightest">
            <div
              id="app"
              className="bg-white w-128 h-70 rounded shadow-md flex card text-grey-darkest"
              style={styles.w128}
            >
              <div className="w-205">
                <img
                  className="w-50 mr-20  h-50 rounded-l-sm"
                  style={styles.img}
                  src={categories[1].img}
                  alt="Wheels"
                />
              </div>
              <div className="w-full flex flex-col">
                <div className="p-4 pb-0 flex-1 mx-10">
                  <h3 className="text-3xl text-grey-darkest">
                    <b>{categories[1].categoryName}</b>
                  </h3>
                  <div className="">
                    <h3 className="text-1xl text-grey-dark">
                      <table className="border text-center text-sm font-light dark:border-neutral-500">
                        <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                          <tr>
                            <th scope="col" className="px-6 py-4">
                              Available Variations
                            </th>
                          </tr>
                        </thead>
                        <tbody>{listItems}</tbody>
                      </table>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full text-center text-sm font-light border">
              <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                <tr>
                  {/* Mapping through specified columns to create table headers */}
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className="border-b dark:border-neutral-500"
                    >
                      {columns &&
                        columns.map((column, colIndex) => (
                          <td
                            key={colIndex}
                            className="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500"
                          >
                            {row[column]}
                          </td>
                        ))}
                      <td>
                        <div className="flex items-center space-x-6 text-lg">
                          <div className="flex items-center gap-6 text-lg">
                            <span
                              onClick={() => decrementCount(rowIndex)}
                              className="ml-2 w-6 h-6 bg-gray-100 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
                            >
                              -
                            </span>
                            <p>{count[rowIndex] || 0}</p>
                            <span
                              onClick={() => incrementCount(rowIndex)}
                              className="w-6 h-6 bg-gray-100 text-2xl flex items-center justify-center hover:bg-gray-300 cursor-pointer duration-300 border-[1px] border-gray-300 hover:border-gray-300"
                            >
                              +
                            </span>
                          </div>
                          <button
                            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                            onClick={() => handleAddToCart(row, rowIndex)}
                          >
                            <CiShoppingCart size={20} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTablesList;
