import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { wheelsData } from "../../assets/dummy/SubCategories/wheelsData";
import { productCategories } from "../../assets/dummy/categories";
import ProductTablesList from "./ProductTablesList";
import env from "react-dotenv";

const SubCategories = () => {
  const items = wheelsData;

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${env.API_URI}api/category/get-product-data/6592a17e4fd2edf6109303c2`
      )
      .then((response) => {
        setData(response.data);
        setColumns(Object.keys(data[0]));
      })
      .catch((err) => console.log(err));
  }, [data]);

  const itemsPerPage = 12;
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset},`
    // );
    setItemStart(newOffset);
  };

  return (
    <div className="mt-20 max-w-container mx-auto px-4">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10"> */}
      {/* <Items currentItems={currentItems} /> */}
      <ProductTablesList
        columns={columns}
        data={data}
        categories={productCategories}
        wheelsData={wheelsData}
      />
      {/* </div> */}
      <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex text-base font-semibold font-titleFont py-10"
          activeClassName="bg-black text-white"
        />

        <p className="text-base font-normal text-lightText">
          {itemStart === 0 ? 1 : itemStart} to {endOffset} of {items.length}
        </p>
      </div>
    </div>
  );
};

export default SubCategories;
